define("discourse/plugins/expose-emails-in/discourse/connectors/category-navigation/expose-email-in", ["exports", "discourse/models/composer", "discourse-common/lib/get-owner", "discourse/lib/show-modal", "discourse/models/permission-type"], function (_exports, _composer, _getOwner, _showModal, _permissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      $('#create-topic').css('display', 'none');
      var logged_in = !!Discourse.User.current();
      logged_in ? component.set('logged_in', true) : component.set('logged_in', false);
      if (typeof args.category.email_in == "string") {
        var email_in = args.category.email_in.split("|").pop();
        component.set('mailto', `mailto:${email_in}`);
      }
      var email_in_allow_strangers = args.category.email_in_allow_strangers;
      if (!email_in_allow_strangers && !logged_in) {
        component.set('stranger_danger', true);
      }
      var canCreateTopicOnCategory = args.category.permission === _permissionType.default.FULL;
      var disable_new_topic_buttons = logged_in && !canCreateTopicOnCategory;
      component.set('disable_new_topic_buttons', disable_new_topic_buttons);
      if (disable_new_topic_buttons) component.set('mailto', '#');
    },
    actions: {
      createTopic() {
        if (Discourse.User.current()) {
          (0, _getOwner.getOwner)(this).lookup('controller:composer').open({
            categoryId: this.category.id,
            action: _composer.default.CREATE_TOPIC,
            draftKey: _composer.default.CREATE_TOPIC
          });
        } else {
          var model = this;
          (0, _showModal.default)('login-prompt', {
            model
          });
        }
      },
      handleEmail() {
        if (this.stranger_danger) {
          var model = this;
          (0, _showModal.default)('login-prompt', {
            model
          });
        } else {
          window.location.href = this.mailto;
        }
      }
    }
  };
});